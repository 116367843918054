@import '../../scss/variables.scss';

.contact-form-wrapper {
  padding-bottom: 100px;
  z-index: 2;
  position: relative;
  p.guarantee {
    background: $green;
    margin-top: 15px;
    display: block;
    margin-bottom: 25px !important;
    text-align: left !important;
    padding: 15px;
    font-weight: bold;
    font-size: 14px !important;
    text-align: left;
    -webkit-box-shadow: 0px 0px 44px -17px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 44px -17px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 44px -17px rgba(0,0,0,0.75);
    font-fAMILY: 'Work Sans', sans-serif;
    width:auto !important;

  }
  .outbound-contact {
    font-size:24px;
    margin-top:25px;
    display:inline-block;
  }
  .contact-direct {
    display:flex;
    flex-wrap:wrap;
    p {
      width:240px !important;
    }
  }

  .flex-contact {
    display:flex;
    align-items:center;
    img {
      margin-right:10px;
      margin-top:25px;
    }
  }

  p.success-message {
    font-size: 18px;
    span {
      background: linear-gradient(120deg, $green 0%, $green 100%);
      background-repeat: no-repeat;
      background-position: 0 60%;
      font-weight: bold;
      background-size: 100% 20%;
      animation:fadeInBg .7s 3;
      animation-fill-mode:forwards;
    }
    @keyframes fadeInbg {
      0%{
        opacity:1;
      }
      50%{
        opacity:0;
      }
      100%{
        opacity:1;
      }
    }
  }

  .loader {
    width: 16px;
    height: 16px;
    position: relative;
    left: 55px;
    border-radius: 50%;
    color: $black;
    background: currentColor;
    box-shadow: 32px 0, -32px 0, 64px 0;
    opacity: 0;
    pointer-events: none;
    transition:.4s;
    &.active {
      opacity: 1;
      transition:.4s;
    }
  }

  .loader::after {
    content: '';
    position: absolute;
    left: -32px;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background: $green;
    animation: move 3s linear infinite alternate;
  }

  @keyframes move {
    0%, 5% {
      left: -32px;
      width: 16px;
    }
    15%, 20% {
      left: -32px;
      width: 48px;
    }
    30%, 35% {
      left: 0px;
      width: 16px;
    }
    45%, 50% {
      left: 0px;
      width: 48px;
    }
    60%, 65% {
      left: 32px;
      width: 16px;
    }

    75%, 80% {
      left: 32px;
      width: 48px;
    }
    95%, 100% {
      left: 64px;
      width: 16px;
    }
  }

  * {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .contact-text-wrapper {

    transform: skewX(-5deg);
    animation-delay: .3s;
    animation: caseIntroWrapper .6s 1;
    animation-fill-mode: forwards;

    @media(max-width: $tablet) {
      margin-left: 25px !important;
      margin-right:25px;
    }

    @keyframes caseIntroWrapper {
      0% {
        transform: skewX(-5deg);
      }
      100% {
        transform: skewX(0deg);
      }
    }

    p {
      top: 15px;
      opacity: 0;
      position: relative;
      animation: caseIntroP 1.2s 1;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      width: 40%;
      transform: skewX(-5deg);
      color: $primary-font-color;
      max-width:537px;

      @media(max-width: $tablet) {
        width: auto;
      }

      @keyframes caseIntroP {
        0% {
          top: 45px;
        }
        100% {
          top: 0;
          opacity: 1;
          transform: skewX(0deg);
        }
      }
    }

    a {
      font-weight: bold;
      color: $black;
      background: linear-gradient(120deg, $green 0%, $green 100%);
      background-repeat: no-repeat;
      background-size: 100% 20%;
      background-position: 0 60%;
      transition:.4s;
      &.review {
        background:none;
        text-decoration:none;
        border-bottom: 2px solid;
        &:hover {
          transition:.4s;
          border-color:$green;
        }
      }
    }
  }

  p.contact-intro-text {
    padding-top: 15px;
    color: $primary-font-color;
  }

  .boilerform.animate-in {
    margin-left: 175px;
    opacity: 0;
    position: relative;
    top: 55px;
    transition: 1s;

    @media(max-width: $tablet) {
      margin-left: 25px;
    }

    button.c-button {
      font-size: 19px;
      font-weight: bold;
      cursor: pointer;
      border: none;
      background: #333;
      margin-left: 2px;
      color: #fff;
      text-transform: uppercase;
      transition: 1s;
      padding: 10px;
      max-width: 250px;
      width: 100%;
      font-family: 'Work Sans', sans-serif;

      &:hover {
        background: #eee;
        color: $tertiary-base-color;
        transition: .3s;
      }
    }

    fieldset.c-form {
      border: none;
      padding-left: 0;
      margin-left: 0;
    }

    .c-form__row {
      display: flex;
      align-items: center;
      position: relative;

      @media(max-width: $tablet) {
        display: block;
        width: calc(100% - 15px);
        text-align: left;

        div {
          text-align: left;
        }
      }
    }

    label.c-label {
      position: absolute;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      color: #8353fd;
      transition: .5s;
      opacity: 0;
      margin-left: 25px;
      top: 0;
      color: #222;
      font-family: 'Work Sans', sans-serif;

      @media(max-width: $tablet) {
        position: relative;
      }
    }

    input {
      height: 48px;
      background: none;
      font-size: 16px;
      text-indent: 5px;
      font-weight: 900;
      transition: .9s;
      transition-delay: .9s;
      position: relative;
      margin-bottom: 40px;
      margin: 23px 19px 10px 2px;
      width: 250px;
      background: #eee;
      color: $black;
      border: none;
      border-bottom: 2px solid #333;

      @media(max-width: $tablet) {
        width: calc(100%);
      }
    }

    textarea {
      height: auto;
      background: none;
      font-size: 16px;
      font-weight: 900;
      transition: .9s;
      transition-delay: .9s;
      position: relative;
      margin: 25px 19px 10px 2px;
      width: 521px;
      color: $black;
      padding: 5px;
      background: #eee;
      border: none;
      border-bottom: 2px solid #333;
      padding: 15px;
      box-sizing: border-box;


      @media(max-width: $tablet) {
        width: calc(100%);
      }

    }
  }

  .boilerform.animate-in.animate-show {
    opacity: 1;
    position: relative;
    top: 0;
    transition: .9s;
    transition-delay: .9s;


    .c-form__row div:nth-child(1) {
      label.c-label {
        opacity: 1;
        transition-delay: 1.20s;
        margin-left: 3px;
      }
    }

    .c-form__row div:nth-child(2) {
      label.c-label {
        opacity: 1;
        transition-delay: 1.5s;
        margin-left: 3px;
      }
    }

    .c-form__row div:nth-child(3) {
      label.c-label {
        opacity: 1;
        transition-delay: 2s;
        margin-left: 3px;
      }
    }

    .c-form__row:nth-child(3) {
      label.c-label {
        opacity: 1;
        transition-delay: 1.20s;
        margin-left: 3px;

      }
    }


  }


  .contact-text-wrapper {
    margin-left: 175px;
    padding-top: 15px;
    padding-bottom: 50px;

    p.not-available {
      font-weight: bold;
      font-size: 12px;
      color: #222;
      margin-bottom: 15px;

    }

    p {
      text-align: left;
      margin: 0;
      font-size: 18px;
      font-size: 18px;
      line-height: 130%;
    }
  }
}