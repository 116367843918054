
@import '../../scss/variables.scss';
.contact-form-wrapper .contact-text-wrapper a {
  @media(max-width:600px) {
   
  }
}
@keyframes dotBlink {
  0%{
    opacity:0;
  }
  50%{
    opacity:1;
  }
  100%{
    opacity:0;
  }
}
.transition-header-wrapper {
    width: 100%;
    height: 70vh;
    background: $off-white;
    display: flex;
    align-items: center;
    margin-bottom:25px;
    @media(max-width:$tablet) {
      margin-bottom:100px;
    }

    .dot {
      animation:dotBlink 1s infinite !important;
      color:$green !important;
      text-shadow: none !important;
      position:relative;
      left:5px;
      top:0 !important;
      opacity:0;
      animation-delay:3s !important;

    }

    p.sub-heading #transition-sub{
        text-shadow:none;
        color:$black;
        span {
          text-shadow:none;
          color:$black;
        }
      }

    .transition-content-wrapper {
      padding-left:53px;
      height: 100%;
      display: flex;
      align-items: center;
      border-right: 1px solid #eeee;
      border-bottom: 1px solid #eeee;
      width: 80%;
      @media(max-width:$tablet){
        padding-left:20px;
        width:80%;
        padding-top:100px;
      }
    }

    p {
      font-size: 1.75vw;
      margin-top: 0;
      font-weight: 900;
      width: 100%;
      text-align:left;
      margin-left:5px;
      line-height:100%;
      position:relative;

      @media(max-width:$tablet){
        font-size: 21px;
        width: 80%;
      }
    }
    span.background-title {
      position: absolute;
      font-size: 100vh;
      left: 55px;
      bottom: -60px;
      opacity: .09;
      font-weight: 900;
      letter-spacing: -2px;
      animation:backgroundTitleIn 1.7s 1;
      animation-fill-mode:forwards;
      animation-delay:.2s;
      animation-timing-function:ease-in-out;
      color:rgba(0,0,0,0.3);
      width:100%;
      overflow:hidden;

      @keyframes backgroundTitleIn {
        0%{
          left:55px;
        }
        100%{
          left:0;
        }
      }
  }

    h2,h1 {
      font-size: 5vw;
      color: $primary-font-color;
      font-weight: 900;
      position: relative;
      line-height: 65%;
      padding-bottom: 0;
      margin-bottom: 21px;
      margin-top: 0;
      top: 15px;
      transform: skewX(-25deg);
      animation: titleIn 2s 1;
      animation-fill-mode: forwards;
      width: 60%;
      text-align: left;
      margin-bottom: 25px;
      text-transform:uppercase;
      font-family:'Work Sans', sans-serif;
      line-height:85%;

      @media(max-width:$tablet){
        font-size:40px;
        width:auto;
        line-height:110%;
        letter-spacing:2px;
      }
    }

    span.title-in {
      display:inline-block;
      transform: skewX(125deg);
      animation:titleIn .5s 1;
      animation-fill-mode:forwards;
      animation-delay:.5s;

      @keyframes titleIn {
        0%{
          transform:skewX(125deg);
        } 
        100%{
          transform:skewX(0deg);
        }
      }
   
      .animated-text {
        position:relative;
        top:15px;
        animation:animatedText .5s 1;
        animation-fill-mode:forwards;
        opacity:0;
        font-weight: 900;
        letter-spacing:1.5px;

        @keyframes animatedText {
          0%{
            top:15px;
          }
          100%{
            top:0;
            opacity:1;
          }
        }




      }

     @for $i from 1 to 1000 {

        .animated-text:nth-child(#{$i}) { animation-delay: $i * 0.05s; }

      }

      .animated-text-para {
        position:relative;
        top:15px;
        animation:animatedText .5s 1;
        animation-fill-mode:forwards;
        opacity:0;
        animation-delay:.8s;
        font-weight: 900;
        color:$primary-font-color;
        line-height:120%;

        @keyframes animatedText {
          0%{
            top:15px;
          }
          100%{
            top:0;
            opacity:1;
          }
        }




      }

      @for $i from 1 to 1000 {

        .animated-text-para:nth-child(#{$i}) { animation-delay: $i * 0.04s; }

      }

      
      span {
        position:relative;
        top:40px;
        transform: translate();
        animation:innerTextIn .6s 1;
         animation-delay:1s;
        animation-fill-mode:forwards;
        opacity:0;
        text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
        color: $white;

        @keyframes innerTextIn {
          0%{
            top:40px;
            opacity:0;
          }
          100%{
            top:0;
            opacity:1;
          }
        }
      }
    }

  .transition-wrapper {
      position: fixed;
      width: 100%;
      height: 100%;
      background: $black;
      background-size: 600% 600%;

      -webkit-animation: AnimationName 30s ease infinite;
      -moz-animation: AnimationName 30s ease infinite;
      animation: AnimationName 30s ease infinite;

      @-webkit-keyframes AnimationName {
          0%{background-position:0% 50%}
          50%{background-position:100% 50%}
          100%{background-position:0% 50%}
      }
      @-moz-keyframes AnimationName {
          0%{background-position:0% 50%}
          50%{background-position:100% 50%}
          100%{background-position:0% 50%}
      }
      @keyframes AnimationName {
          0%{background-position:0% 50%}
          50%{background-position:100% 50%}
          100%{background-position:0% 50%}
      }
      bottom:0;
      background-size:cover;
      display: flex;
      align-items: center;
      z-index:9;
      animation:transitionWrapper 1.2s 1;
      animation-fill-mode:forwards;
      bottom:0;

      @keyframes transitionWrapper {
        0%{
          height:100%;
        }
       
        100%{
          height:0;
        }
      }
    }
}


//miny sub heading 
span.sub-page-miny-sub-heading-wrapper{
    display: block;
    top: 20px;
    position: relative;
    width: 147px;
    left:0;
    margin-bottom: 15px;
    display:none;

    @media(max-width:$tablet){
      left:0 !important;
    }

    span:nth-child(1) {
      width: 8px;
      position: absolute;
      height: 2px;
      background: $primary-font-color;
      margin-top: 10px;
      left: 0;
      opacity:0;
      animation:spanOnInOne .6s 1;
      animation-fill-mode:forwards;
      animation-delay:.5s;
      top:2.5px;

      @keyframes spanOnInOne {
        0%{
          opacity:0;
          width:8px;
        }
        100%{
          opacity:1;
          width:45px;
        }
      }
  }

  span:nth-child(2){
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: $secondary-base-color;
    position:relative;
    right:-45px;
    opacity:0;
    animation:spanOnInTwo 1.6s 1;
    animation-fill-mode:forwards;
    animation-delay:.5s;

    @keyframes spanOnInTwo {
      0%{
        opacity:0;
        right:-45px;
      }
      100%{
        opacity:1;
        right:-15px;
      }
    }
  }

}

