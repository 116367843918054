@import '../../scss/variables.scss';

.brief-form {
	h3 {
		padding-top:25px;
		padding-bottom:25px;
		margin-bottom:25px;
		text-transform:uppercase;
		font-weight:900;
			span {
				background: linear-gradient(120deg, #39FF14 0%, #39FF14 100%);
			    background-repeat: no-repeat;
			    background-size: 100% 20%;
			    background-position: 0 60%;
			}
		}
	.c-form__row {
		flex-wrap:wrap;
		div {
			width:50%;
			position:relative;
			left:-15px;
			@media(max-width:1200px) {
				width:100%;
				left:0;
			}

		}
		input,
		textarea {
			width: calc(100% - 50px) !important; 
			@media(max-width:$tablet) {
				width:100% !important;
			}
			font-size:16px;
	    	font-family:$secondary_font;
	    	font-weight:normal;
		    &::placeholder {
		    	font-size:14px;
		    	color:rgba(0,0,0,0.4);
		    	line-height:140%;
		    	font-famiky:'Helvetica Neue',Helvetica,arial,sans-serif;
		    	font-size:16px;
		    	font-weight:normal;
		    	font-weight:300;
		    }
		}
		label.c-label {
			opacity: 1 !important;
			margin-left:3px !important;
			line-height:130%;
		}
	
	}
}