@import '../../scss/variables.scss';

.google-reviews {
    position: fixed;
    bottom: 0;
    right: 15px;
    bottom: 15px;
    display: flex;
    align-items: center;
    background:#fff;
    color:$black;
    text-decoration:none;
    box-shadow:0 2px 5px 0 rgba(0,0,0,.26);
    padding:15px;
    border-top:4px solid $green;
    cursor:pointer;
    transition:.4s;
    bottom:-110px;
    animation:GoogleReviews .6s 1;
    animation-fill-mode:forwards;
    animation-delay:5s;
    opacity:1 !important;

    @keyframes GoogleReviews {
    	0%{
    		bottom:-110px;
    	}
    	100%{
    		bottom:15px;
    	}
    }
    &:hover {
    	background:#eee;
    	transition:.4s;
    }
    p {
    	margin:0;
    }
    .icon-wrap {
    	flex:none !important;
    	padding-right:15px;
    }
    .content-wrap {
	    width:150px;
	    text-align:left;
	}
	.row.row-one p {
		font-weight:bold;
		color:$black;
	}
	.row.row-two {
		display:flex;
		align-items:center;
		justify-content: start;
		padding-top:5px;
		padding-bottom:5px;
		p {
			color:#fb8e28;
			font-size:20px;
			font-weight:bold;
			position:relative;
			top:2px;
			font-weight:400;
		}
	}
	.row.row-three p {
	    color: #8d8d8d;
	} 
}