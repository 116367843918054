.careers-page {
	a.grape-non-active {
	    color: #eee !important;
	}
	.contact-form-wrapper .contact-text-wrapper a {
		background:none;
	}
	p.guarantee,
	p.review {
		display:none;
	}
	.header-component-wrapper .hamburger-inner, .header-component-wrapper .hamburger-inner::before, .header-component-wrapper .hamburger-inner::after {
		background-color:#eee;
	}
	.footer-wrapper {
		background:#111;
	}
	.footer-wrapper .sub-wrap-one ul .footer-heading,
	.footer-wrapper .sub-wrap-two li.footer-heading.location {
		background:#111;
		color:#eee;
		border-bottom:1px solid rgba(255,255,255,0.2);
		border-top:1px solid rgba(255,255,255,0.2);
	}
	.footer-wrapper ul li,
	.footer-wrapper ul li a,
	.footer-wrapper .sub-wrap-two a, .footer-wrapper .sub-wrap-two li.location-display,
	.footer-wrapper .sub-wrap-two .footer-heading {
		color:#eee;
	}
	.footer-wrapper .sub-wrap-one {
		border-right:1px solid rgba(255,255,255,0.2);
	}
	.footer-wrapper .copyright-row {
		border-top:1px solid rgba(255,255,255,0.2);
	}

}
.careers-wrapper {
    background: #111;
    .footer-navigation-action-wrapper.animate-in {
    	 a .filter-hover {
	    	background:#111;
	    }
	    a:nth-child(1) {
	    	border-right:1px solid rgba(255,255,255,0.1);
	    }
	    a {
	    	border-top:1px solid rgba(255,255,255,0.1);
	    }
	    p {
	    	color:#eee;
	    }
    }
    .transition-header-wrapper {
    	background:#111;
    	span.title-in span {
    		 text-shadow: -1px -1px 0 #eee, 1px -1px 0 #eee, -1px 1px 0 #eee, 1px 1px 0 #eee;
   			 color: #111;
    	}
    	 p.sub-heading #transition-sub span {
    	 	color:#eee;
    	 }
    	 span.background-title {
    	 	color:rgba(255, 255, 255, 0.3);
    	 }
    }
    .contact-form-wrapper {
    	color:#eee;
    	 .contact-text-wrapper a {
    	 	color:#eee;
    	 }
    	p.contact-intro-text {
    		color:#eee;
    	}
    }
    .transition-content-wrapper {
    	border-right: 1px solid rgba(255, 255, 255, 0.1);
    	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  
}