@import '../../scss/variables.scss';

.contact-action-wrapper.animate-in {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#fbfbfb;
    position: relative;

    @media(max-width:$tablet){
      height:400px;
    }

    &:hover {
      .contact-link {
        transition:.4s;
      }
    }
    &:hover span.hover-under{
        width:100%;
        transition:.9s;

      }
      .contact-action-flex-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        > div {
          text-align:left;
        }
    }

      span.miny-sub-heading-wrapper.animate-in {
          display: block;
          position: relative;
          width: 147px;
          margin-bottom: 15px;
          position: relative;
          left: 0;
          text-align: center;
          margin: 0;
          text-align: center;
          justify-content: center;
          align-items: center;
          left: -67px;


          @media(max-width:$tablet){
            top:0px;
            margin-left:64px;
          }

          span:nth-child(1){
            background: $primary-font-color;
          }
          span:nth-child(2) {
              right: -45px;

          }
      }

    .number-item-wrapper {
       left:0;
       top:25px;
       opacity:0;
       transition:.9s;
    }

    a {
      position: relative;
      width: 100%;
      height: 100%;
      display: block;
      text-align: center;
      text-decoration:none;
    }

    span.contact-link {
      font-size: 9vw;
      font-weight: bold;
      color: $primary-font-color;
      text-decoration: none;
      font-family:'Work Sans', sans-serif;
      text-transform:uppercase;
      text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
      color: $white;
      transition:.6s;
      @media(max-width:$tablet){
        font-size:57px;
      }
  

    }

  span.hover-under {
      display: block;
      height: 2vh;
      width: 0;
      position: relative;
      background: $secondary-base-color;
      left: 0;
      bottom: 15px;
      margin-left: 0;
      transition: ease-in .5s;



  }

}

.contact-action-wrapper.animate-in.animate-show {


  .number-item-wrapper {
       top:0px;
       opacity:1;
       transition-delay:.5s;
       transition:.9s;
    }



    span.miny-sub-heading-wrapper.animate-in.animate-show span:nth-child(2) {
      right: -30px;
      top:3px;

  }

  @media(max-width:$tablet){
     span.hover-under { 
      transition-delay:.8s;
      width:100%;
      bottom:0;

    }
  }

}