@import '../../scss/variables.scss';

.body-content-wrapper.animate-in {
  width: calc(100% - 130px);
  min-height: 500px;
  background: $primary-dark;
  display: flex;
  padding-left: 130px;

 .col-50 {
   &:nth-child(1) {
     border-right:1px solid rgba(0,0,0,0.1);
      span.miny-sub-heading-wrapper.initial-transition-header.animate-show{
      width:40px;
     }
   }
 }

.member-count {
  width:100%;
  padding:25px 50px;
  background:none;
  border:1px solid rgba(0,0,0,0.1);
  color:$black;
  text-transform:uppercase;
  font-weight:bold;
  font-family:'Work Sans', sans-serif;
  font-size:18px;
}



  @media(max-width: $tablet) {
    padding-left: 25px;
    display: block;
    width: calc(100% - 25px);


    .miny-sub-heading-wrapper.animate-in {
      left: 0 !important;
    }
  }


  p {
    line-height: 140%;
  }


  .availability {
    color: $secondary_base_color;
    font-weight: bold;
    font-size: 12px;
  }

  .scroll {
    position: absolute;
    top: -125px;
    left: -50px;
    color: $black;

    @media(max-width: $tablet) {
      display: none;
    }

    button {
      background: none;
      border: none;
      border-bottom: 2px solid $secondary-base-color;
      padding-bottom: 9px;
      font-size: 13px;
      padding: 0;
      padding-bottom: 5px;
      font-weight: bold;
      text-transform: lowercase;
      color: $secondary-base-color;
      position:relative;
      top:-175px;
    }
  }

  div {
    flex: 1;
    box-sizing: border-box;
    padding: 50px;
    text-align: right;
    min-height: 400px;
    position: relative;

    @media(max-width: $tablet) {
      padding: 25px;
    }


  }

  h6.home-sub-row-heading {
    font-size: 2.3vw;
    font-weight: bold;
    width: 100%;
    box-sizing: border-box;
    top: 65px;
    opacity: 0;
    position: relative;
    transition: .5s;
    color: $secondary-base-color;
    font-family: 'Work Sans', sans-serif;
    line-height: 110%;


    @media(max-width: $tablet) {
      font-size: 27px;
      line-height: 110%;
    }
  }

  .team-wrapper-section {
    flex-wrap:wrap;
    display:flex;
    @media(max-width:$tablet) {
      width:100%;
    }
    article {
      width:50%;
      padding:25px;
      box-sizing:border-box;
      opacity:0;
      top:25px;
      position:relative;
    
   
      @media(max-width:$tablet) {
        width:50%;
        border:1px solid rgba(0,0,0,0.1);
        margin-bottom:15px;
      }
      .highlight {
        background: linear-gradient(120deg, $green 0%, $green 100%);
        background-repeat: no-repeat;
        background-size: 100% 20%;
        background-position: 0 60%;
      }
      h5 {
        text-transform:initial !important;
        font-size:22px !important;
      }

      .headshot-wrapper {
        width:150px;
        height:150px;
        border-radius:100%;
        overflow:hidden;
        position:relative;
        display:block;
        opacity:1;
        margin-bottom:15px;
        @media(max-width:$tablet) {
          width:100px;
          height:100px;
        }

        img {
          width:100%;
          height:100%;
          object-fit:cover;
          filter:grayscale(1);
          
        }
      }
    }
  }

  &.animate-show {
    article {
      opacity:1;
      top:0;
      transition:.4s;
      position:relative;
      transition-delay:1s;
      @media(max-width:$tablet) {
        min-height:300px;
      }
    }
  }

  div:nth-child(2) {
    display: flex;
    align-items: center;
    text-align: left;
    position: relative;
    animation: initBodyTwo .9s 1;
    animation-fill-mode: forwards;
    top: 25px;
    opacity: 0;
    animation-delay: .8s;
    background-color: $white;
    width: 50%;

    @media(max-width: $tablet) {
      min-height: 280px;
      width:100%;
      padding-left:0;
      padding-right:0;
    }

    @keyframes initBodyTwo {
      0% {
        opacity: 0;
        top: 25px;
      }
      100% {
        opacity: 1;
        top: 0;
      }
    }


    h5 {
      position: relative;
      font-size: 2em;
      color: $black;
      font-family: 'Work Sans', 'Roboto', sans-serif;
      font-weight: 900;
      margin: 0;
      transition: .5s;
      text-align: left;
      margin-bottom:10px;
      @media(max-width: $tablet) {
        font-size: 40px;
      }

    }

    p {
      position: relative;
      color: $black;
      width: 200px;
      transition: .5s;
      text-align: left;
      margin-bottom: 5px;
      line-height: 130%;
      margin-top:0;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      font-family: 'Work Sans', sans-serif;
      @media(max-width:$tablet) {
        max-width:150px;
      }
    }

    p.date {
      border-top: none !important;
      font-size: 11px;
      color: $black;
      font-weight: bold;
    }

    p.collab {
      border: none !important;

      padding: 0;
      color: $black;
      font-weight: bold;
      font-size: 12px;
    }

    a {
      text-decoration: none;
    }

    .filter-hover {
      position: absolute;
      width: 0%;
      height: 100%;
      background: #292929eb;
      left: 0;
      top: 0;
      transition: .5s;
      animation: filterHoverInHome .9s 1;
      animation-fill-mode: forwards;
      animation-delay: 1.2s;
      background: $white;


      @keyframes filterHoverInHome {
        0% {
          width: 0%;
        }
        100% {
          width: 60%;
        }
      }


      @media(max-width: $tablet) {
        @keyframes filterHoverInHome {
          0% {
            width: 0%;
          }
          100% {
            width: 80%;
          }
        }
      }


    }

    span.init-filter {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      left: 0;
      top: 0;
      position: absolute;
      outline: 1px solid rgba(0, 0, 0, 0.1);
    }

    span.filter {
      position: absolute;
      bottom: 0;
      width: 60%;
      margin-left: 60%;
      height: 100%;
      transition: .5s;
      transition-delay: .5s;
      right: auto;
      left: 0;
      margin-left: 0;
      background: $black;


    }
  }

  .animate-in:nth-child(1) {


    p {
      position: relative;
      top: 45px;
      opacity: 0;
      transition: .5s;
      color: $secondary-base-color;
      font-size: 18px;
      line-height: 130%;
      margin-top:0;
      margin-bottom:25px;
    }

  }

  .animate-in.animate-show:nth-child(1) {


    p {
      position: relative;
      top: 0;
      opacity: 1;
      transition: 1.2s;
      transition-delay: .5s;
      line-height: 130%;
    }

    h6.home-sub-row-heading {
      top: 0;
      transition: 1.2s;
      opacity: 1;
    }

  }


  .animate-in:nth-child(2) {


    .filter-hover {
      height: 0%;
      transition: .6s;
      transition-delay: .3s;
    }

    .filter {
      margin-left: 0%;
      width: 100%;
      transition: .6s;
      transition-delay: .4s;

    }

    h5 {
      margin-left: 25px;
      transition: .5s;
      transition-delay: .3s;
      color: $secondary-base-color;
    }

    p {
      margin-left: 25px;
      transition: .5s;
      padding-right: 20%;
      font-size: 16px;
      line-height: 130%;
    }


  }


  .animate-in.animate-show:nth-child(2) {


    .filter-hover {
      height: 100%;
      transition: .6s;
      transition-delay: .5s;
    }


    .filter {
      margin-left: 30%;
      width: 40%;
      transition: .9s;
      transition-delay: .8s;

    }

    h5 {
      margin-left: 25px;
      transition: .5s;
      transition-delay: .7s;
      color: $full-black;
    }

    p {
      margin-left: 25px;
      transition: .5s;
      padding-right: 20%;
    }

    &:hover .filter-hover {
      height: 0%;
      transition: .6s;
      transition-delay: .5s;
    }

    &:hover .filter {
      margin-left: 0%;
      width: 100%;
      transition: .6s;
    }

    &:hover h5 {
      margin-left: 0px;
      transition: .5s;
      transition-delay: .3s;
      color: #fff;
    }

    &:hover p {
      margin-left: 0px;
      transition: .5s;
      padding-right: 20%;
    }


  }
}

.body-content-wrapper.animate-in .team-wrapper-section article:nth-child(1) img {
    transform: scale(1.8);
}
