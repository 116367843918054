.terms-container {
  text-align: left;
  margin-left: 175px;
  width: calc(100% - 375px);
  padding-bottom: 100px;
  position: relative;

  @media(max-width: 1100px) {
    width: calc(100% - 40px);
    margin-left: 20px;
  }

  p, li {
    font-size: 18px;
    line-height: 140%;
    color: #333;
  }
}