@import '../../scss/variables.scss';

.service-offering.animate-in {
  display:flex;
  flex-wrap:wrap;
  text-align:left;
  text-align: left;
  margin: 0 0px 60px 175px;
  transform: skewX(-5deg);
  animation-delay: 1s;
  animation: caseIntroWrapper .6s 1;
  animation-fill-mode: forwards;
  border:1px solid rgba(0,0,0,0.1);

  @media(max-width:$tablet) {
    margin:0 0 60px 26px;
    margin-left:0;
  }

  img {
    background: linear-gradient(120deg, #39FF14 0%, #39FF14 100%);
    background-repeat: no-repeat;
    background-size: 100% 20%;
    background-position: 0 60%;
    width:40px;
    height:40px;
  }

  .card {
    width:50%;
    box-sizing:border-box;
    padding-right:50px;
    padding:50px;
    opacity:0;
    top:15px;
    position:relative;
    @media(max-width:$tablet) {
      width:100%;
      padding:50px 25px;
    }
    &:nth-child(1) {
      border-bottom:1px solid rgba(0,0,0,0.1);
      border-right:1px solid rgba(0,0,0,0.1);
    }
    &:nth-child(2) {
      border-bottom:1px solid rgba(0,0,0,0.1);
    }
    &:nth-child(3) {
      border-right:1px solid rgba(0,0,0,0.1);
    }
     &:nth-child(5) {
      border-right:1px solid rgba(0,0,0,0.1);
    }
    h3 {
      font-size:1.55vw;
      @media(max-width:$tablet) {
        font-size:21px;
      }
    }
    p {
      font-size:18px;
      line-height:140%;
      max-width:550px;
    }
  }
}

.service-offering.animate-in.animate-show {
  .card {
    top:0;
    opacity:1;
    transition:.5s;
    transition-delay:1s;
  }

}