@import '../../scss/variables.scss';
.partners {
	display:flex;
	align-items:center;
	justify-content: center;
	padding-top:50px;
	padding-bottom:75px;
	position:relative;
	z-index:0;
	@media(max-width:$tablet) {
		padding-bottom:00px;
	}
	&::before {
		content: '';
	    position: absolute;
	    left: 0;
	    height: 100%;
	    width: 150px;
	    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 43%, rgba(255, 255, 255, 0) 100%);
	    z-index: 2;
	}
	&::after {
		content: '';
	    position: absolute;
	    right: 0;
	    height: 100%;
	    width: 150px;
	    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 43%, rgba(255, 255, 255, 0) 100%);
	    z-index: 1;
	    transform:rotate(180deg);
	}
	a {
		cursor:pointer;
	}
	.rfm-marquee:nth-child(1) {
		margin-right:0px;
	}
	img {
		filter:grayscale(1);
		height:35px;
		margin:0 25px;
		&.figma {
			height:50px;
		}
		&.cpanel {
			height:30px;
		}
		&.acf {
			height:50px;
		}

		&.vercel {
			height:30px;
		}
		&.aws {
			height:50px;
		}
		&.next {
			height:25px;
		}
		&.react {
			height:60px;
		}
	}
}