@import './../scss/variables.scss';


.App {
  text-align: center;
  background:$primary-dark;
  min-height:100vh;
  width:100%;
  transition:.4s;
  .inline-link {
    color:$black;
    text-decoration:none;
    font-weight:bold;
    border-bottom:2px solid;
    transition:.3s;
    &:hover {
      border-color:$green;
      transition:.4s;
    }
  }
}

