@import '../../scss/variables.scss';
svg.menu-arrow {
    position: absolute;
    right: 70px;
    top: 70px;
    animation:arrowUpDown 1s infinite;
    animation-fill-mode:forwards;
    @media(max-width:800px) {
      display:none;
    }
    
  }
  

  @keyframes arrowUpDown {
    0%{
      top:70px
    }
    50%{
      top:80px;

    }
    100%{
      top:70px;
    }
  }
.projects-wrapper {
  margin: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  animation: navIn 2s 1;
  animation-delay: .5s;
  animation-fill-mode: forwards;
  position: relative;
  opacity: 0;
  left: 100px;
  
  div.card {
    height: 200px;
    width: 33.33%;
    background: purple;
    display: flex;
    margin: 15px;
    box-sizing: border-box;
    padding: 10px;
    font-family: 'Work Sans', sans-serif;
    text-transform: uppercase;
    cursor: pointer;
    transition: .4s;
    border: 15px solid orange;
    text-align: left;
    border-radius: 25px;

    &:hover {
      background: red;
      transiton: .4s;
    }
  }

}

.header-component-wrapper {
  position: absolute;
  width: 100%;
  z-index: 999;

  .cake-flash {
    animation:cakeFlash 1s infinite;

  }
  @keyframes cakeFlash {
    0%{
      fill: $white;
    }
    50%{
      fill: $green;
    }
    100%{
      fill: $white;
    }
  }
  .header-view-left a {
    font-family: "Work Sans", sans-serif !important;
    text-transform: uppercase;

  }

  .header-view-right {

    font-family: "Work Sans", sans-serif;
    text-transform: uppercase;

    a {

      font-family: "Work Sans", sans-serif;
      text-transform: uppercase;
      font-weight: bold;

    }
  }


  .header-navigation-sub {

    a {
      transition: 1s;
      color: $black;
      text-decoration: none;
      font-size: 25px;

      &:hover {
        color: $primary-font-color;
      }
    }

    .blink_dot {
      animation: blinkDot 1.2s infinite;

      @keyframes blinkDot {
        0% {
          opacity: 1;
          color: $green;
        }
        50% {
          opacity: 0;
        }
        100% {
          opacity: 1;
          color: $green;
        }
      }

    }

    .grape-active {

      animation: grapeActive 1s 1;
      animation-fill-mode: forwards;
      left: 25px;
      position: relative;
      color: $white;

      @keyframes grapeActive {
        0% {
          color: rgba(255, 255, 255, 0.0);
          left: 25px;
        }
        50% {
          color: rgba(255, 255, 255, 0.0);
        }
        100% {
          color: $white;
          left: 0;
        }
      }
    }

  }

  .modal.modal-on {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0px 25px;
    background: $secondary-base-color;
    color: $black;
    font-weight: bold;
    cursor: pointer;
    z-index: 9;
    bottom: -55px;
    animation: modalIn .5s 1;
    animation-fill-mode: forwards;
    transition: .4s;
    -webkit-box-shadow: 0px 0px 47px -20px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 47px -20px rgba(0, 0, 0, 0.75);
    background: linear-gradient(to right, #8100fd 1%, #6100fd 43%, #4e00fd 73%, #2d00fe 100%);

    &:hover {
      transition: .5s;
      background: $primary-font-color;
    }

    @keyframes modalIn {
      0% {
        bottom: -55px;
      }
      100% {
        bottom: 0;
      }
    }


    img {
      width: 10px;
      position: absolute;
      left: 7px;
      top: 7px;
      fill: $black;
      filter: invert(1);
    }

    a {
      color: $black;
      text-decoration: none;
      margin-left: 5px;
      font-size: 20px;
      top: 1px;
      transition: .4s;

      &:hover {
        color: wheat;
        transition: .3s;
      }
    }
  }

  .modal.modal-off {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 0px 25px;
    background: #d6382c;
    color: $primary-font-color;
    font-weight: bold;
    cursor: pointer;
    z-index: 9;
    bottom: 0;
    animation: modalOff .5s 1;
    animation-fill-mode: forwards;

    @keyframes modalOff {
    0% {
    bottom: 0;
  }

    100% {
      bottom: -55px;
    }
  } img {
  width:10 px;
  position:absolute;
  left:7 px;
  top:7 px;
  } a {
  color:#111;
  text-decoration:none;
  margin-left:5 px;
  font-size:20 px;
  top:1 px;
  transition:.4 s;

  &:hover {
  color:wheat;
  transition:.3 s;
  }
  }
  }


  .demonstrate {
    text-align: left;
    top: 0;
    position: fixed;
    background: #ff2925;
    color: #eee;
    font-weight: 700;
    display: block;
    box-sizing: border-box;
    padding: 5px 33px;
    margin: auto;
    left: 0;
    right: 0;
    text-align: center;
    width: 439px;
    bottom: 0;
    top: auto;
    text-transform: uppercase;
    right: 0;
    margin-right: 0;

  }

  /* burger menu */
  .hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
  }

  .hamburger:hover {
    opacity: 0.7;
  }

  .hamburger.is-active:hover {
    opacity: 0.7;
  }

  .hamburger.is-active .hamburger-inner::before,
  .hamburger.is-active .hamburger-inner::after {
    background-color: $black;
  }

  .hamburger.is-active .hamburger-inner {
    background-color:$white;

  }

  .hamburger-box {
    width: 40px;
    height: 24px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

  }


  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 25px;
    text-align: center;
    height: 4px;
    background-color: $black;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
    margin: auto;
  }

  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
  }

  .hamburger-inner::before {
    top: -7px;
  }

  .hamburger-inner::after {
    bottom: -7px;
  }

  .hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
    transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
  }

  .hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
    opacity: 0;
    transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
  }


  .hamburger--minus.is-active .hamburger-inner::before {
    top: 0;
    background-color: $black;
  }

  .hamburger--minus.is-active .hamburger-inner::after {
    bottom: 0;
    background-color: $black;
  }

  /* other */

  .header-navigation {
    display: flex;
    position: relative;
    z-index: 9;
    padding: 0 37px;
    @media(max-width: $tablet) {
      padding: 0;
    }

    div {
      flex: 1;
      padding: 10px;
      box-sizing: border-box;
    }

    div:nth-child(2) {
      text-align: right;
    }

    div:nth-child(1) {
      text-align: left;
    }
  }


  /* header nav not clicked */
  .header-view {
    position: absolute;
    width: 100%;
    display: flex;
    top: 0;
    height: 0;
    overflow: hidden;
    transition: .5s;

    @media(max-width: $tablet) {
      display: block;
    }


    .header-view-sub {
      flex: 1;
      transition: height .5s;

      ul {
        opacity: 0;
      }

    }


    .header-view-sub.header-view-left {
      transition: .6s;
      height: 0;
      box-sizing: border-box;
      border-bottom: 1px solid $tertiary-transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: right;
      background: $black;

      @media(max-width: $tablet) {
        text-align: left;
      }


      span {
        width: 100%;
        display: block;
      }

      .Home.link-on {
        color: $home-primary-color;
        transition: .6s;

      }

      .About.link-on {
        color: $about-primary-color;
        transition: .6s;
      }

      .Work.link-on {
        color: $work-primary-color;
        transition: .6s;
      }

      .Team.link-on {
        color: $team-primary-color;
        transition: .6s;
      }

      .Knowledge.link-on {
        color: $knowledge-primary-color;
        transition: .6s;
      }

      .Contact.link-on {
        color: $contact-primary-color;
        transition: .6s;
      }

      .Blog.link-on {
        color: $blog-primary-color;
        transition: .6s;
      }


      a {

        font-size: 5vw;
        line-height: 99%;
        font-family: $secondary-font;
        font-weight: 900;
        color: $black;
        padding-right: 25px;
        text-decoration: none;
        letter-spacing: -2px;
        font-family: 'Work Sans', sans-serif;
        text-transform: uppercase;
        font-family: "Work Sans", sans-serif;
        text-transform: uppercase;
        color: $black;
        text-shadow: -1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;

        @media(max-width: $tablet) {
          font-size: 50px;
          padding-left: 20px;
        }

        &:hover {
          transition: .4s;
          color: $white;
          text-shadow: none;
          color:$white;
        }
      }

      ul {
        list-style: none;
        padding-left: 0;

        li.header-view-heading {
          font-weight: bold;
          font-size: 12px;
          border-bottom: 1px solid rgba(255,255,255,0.3);
          padding-bottom: 5px;
          margin-bottom: 5px;
          color: $primary-dark;
          padding-right: 250px;
          display: inline-block;
          font-family: $primary-font;
          text-transform: uppercase;
          font-family: 'Work Sans', sans-serif;
          text-transform: uppercase;
          @media(max-width: $tablet) {
            padding-left: 20px;
            margin-bottom:15px;
            border-bottom:1px solid rgba(255,255,255,0.1);
          }
          &.no-highlight {
            text-shadow:none;
            color:$white;
          }

        }


      }
    }

    .header-view-sub.header-view-right {
      transition: .6s;
      height: 0;
      box-sizing: border-box;
      border-left: 1px solid rgba(255, 255, 255, 0.4);
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      display: flex;
      align-items: center;
      text-align: left;
      background: $black;
      line-height:110%;

      ul {
        list-style: none;
        padding-left: 0;

        

        li {
          color:$black;
          text-shadow:-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
        }

        a {
          color: $black;
          line-height: 140%;
          text-decoration: none;
          font-weight: 900;
          line-height: 110%;
          transition: .5s;
          color:$black;
          text-shadow:-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white;
          &.no-highlight {
            text-shadow:none;
            color:$white;
          }
          &:hover {
            transition: .3s;
            text-shadow:none;
            color:$white;
          }

        }

        li {
          padding-left: 25px;
          font-size: 2vw;
          font-weight: 900;
          line-height: 110%;
          color: $black;

        }

        li.header-view-heading {
          font-size: 12px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.3);
          padding-bottom: 5px;
          margin-bottom: 5px;
          color: $black;
          text-transform: uppercase;
          margin-bottom: 20px;
          margin-top: 25px;
          &.no-border {
            border-bottom:none;
          }

          &.no-highlight {
            text-shadow:none;
            color:$white;

          }
        }
      }


    }

    .header-view-sub.header-view-bottom {
      width: 100%;
      height: 00vh;
      background: $black;
      bottom: 0;
      position: fixed;
      transition: .5s;
      transition-delay: 0;
      border-top: 1px solid rgba(255, 255, 255, 0.3);

      div.social-link-list {

        display: flex;
        align-items: center;
        justify-content: center;
        left: 39px;
        position: relative;
        @media(max-width:800px){
          justify-content: flex-start;
          left:0;
          flex-wrap:wrap;
        }
      

        ul {
          opacity: 1;
          list-style: none;
          display: flex;
          align-items: center;
          padding: 15px 27px;
          margin: 0;
          position: relative;
          left: 25px;
          border-left: 1px solid $tertiary-transparent;

          img {
            width: 27px;
          }

          &.tel-contact {
            display:none;
            @media(max-width:800px){
              display:block;
              width:100%;
              text-align:left;
              font-size:30px !important;
              text-decoration:none;
            }
          }

          li {
            color: $black;
            font-weight: bold;
            font-family: $primary-font;
            text-transform: uppercase;
            text-decoration:none;

            a {
              padding: 10px 5px;
            }
          }
        }

      }


    }

    .Home.header-view-sub.header-view-bottom {
      background-image: url('/static/media/octopus-blue.920137de.jpeg');
      background-size: cover;
    }

    .About.header-view-sub.header-view-bottom {
      background-image: url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size: cover;
    }

    .Work.header-view-sub.header-view-bottom {
      background-image: url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size: cover;
    }

    .Team.header-view-sub.header-view-bottom {
      background-image: url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size: cover;

    }

    .Contact.header-view-sub.header-view-bottom {
      background-image: url('/static/media/octopus-blue.920137de.jpeg');
      background-size: cover;
    }

    .Knowledge.header-view-sub.header-view-bottom {
      background-image: url('/static/media/octopus-blue.920137de.jpeg');
      background-size: cover;
    }

    .Blog.header-view-sub.header-view-bottom {
      background-image: url("/static/media/the-octopus.a9d89c71.jpeg");
      background-size: cover;
    }

  }


  /* header nav clicked! */
  .header-view.header-view-show {
    height: 80vh;


    .header-view-sub.header-view-left {
      height: 100%;
      transition: height .5s;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: right;
      overflow: hidden;


      @media(max-width: $tablet) {
        text-align: left;
      }


      a {

        opacity: 1;
        font-family: "Work Sans", sans-serif;
        text-transform: uppercase;

      }

      //    @for $i from 1 to 1000 {
      //    a:nth-child(#{$i}) { transition-delay: $i * 0.8s; }
      //     }


      span {
        width: 100%;
      }

      img {
        width: 20vw;
      }

      ul {
        opacity: 1;
      }
    }

    .header-view-sub.header-view-right {
      height: 100%;
      transition-delay: 2.5s;
      transition: height .8s;
      @media(max-width:800px) {
        display:none;
      }

      ul {
        opacity: 1;
      }
    }

    .header-view-sub.header-view-bottom {
      height: 30vh;
      transition: .5s;
      ul {
        padding:0;
        list-style:none;
      }
      a {
        color:$white;
      }
      .terms {
        font-size: 15px;
        color: $black;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

}
svg.menu-arrow.closed path {
    stroke: #fff !important;
}