@import '../../scss/variables.scss';


@keyframes dotBlink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.single-blog-page-wrapper.animate-in {

  .dot {
    animation: dotBlink 1s infinite !important;
    color: $green !important;
    text-shadow: none !important;
    position: relative;
    left: 5px;
    top: 0 !important;
    opacity: 0;
    animation-delay: 3s !important;

  }

  h1 {
    font-family:'Work Sans', sans-serif;
  }

  .single-blog-body-content {


    img {
      margin: 20px 0px;
      width: 100%;
    }

    color: $primary-font-color;
    text-align: left;
    margin-left: 175px;
    width: calc(100% - 375px);
    padding-bottom: 100px;
    position: relative;

    @media(max-width: $tablet) {
      margin-left: 20px;
      width: calc(100% - 40px);
    }

    .sub-quote {
      border-left: 1px solid;
      padding: 35px 41px 35px 41px;
      background: $primary-font-color;
      box-sizing: border-box;
      color: #fff;
      font-weight: bold;

      a {
        color: #fff;
      }
    }

    p, li {
      font-size: 16px;
      line-height: 140%;
    }

    h3 {
      font-size: 32px;
      line-height: 100%;
      font-family: 'Work Sans', sans-serif;
      text-transform: uppercase;
    }

    h4 {
      font-size: 20px;
      font-family: 'Work Sans', sans-serif;
    }

    a {
      color: $secondary-base-color;
      transition: .5s;
      font-weight: bold;

      &:hover {
        color: #eee;
        transition: .3s;
      }
    }
  }

  .prev-next-article-footer {

    display: flex;
    align-items: center;
    padding-bottom: 8px;

    a {
      margin: 0 30px 0 0px;
      font-weight: bold;
      font-size: 28px;
      text-decoration: none;
      transition: .5s;
      color: $primary-font-color;
      flex: 1;
      font-family: 'Work Sans', sans-serif;

      &:hover {
        color: #eee;
        transition: .3s;
      }
    }
  }

  .entry-text.article-on {


    .animate-line {
      position: absolute;
      left: 0;
      height: 1px;
      background: $tertiary-base-color;
      width: 0;
      display: block;
      bottom: 0;
      animation: LineLeftRight .9s 1;
      animation-fill-mode: forwards;

      @keyframes LineLeftRight {
        0% {
          left: 0;
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }

  }

  .entry-text.article-off {

    .animate-line {
      position: absolute;
      right: 0;
      height: 1px;
      background: $secondary-base-color;
      width: 0;
      display: block;
      bottom: 0;
      animation: RightToLeft .9s 1;
      animation-fill-mode: forwards;

      @keyframes RightToLeft {
        0% {
          right: 0;
          width: 0;
        }
        100% {
          width: 100%;
        }
      }
    }

  }

  .entry-text {
    min-height: 60vh;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    position: relative;
    padding-top: 55px;
    background: $white;
    padding-top: 100px;

    @media(max-width: $tablet) {
      padding-top: 130px;
    }

    h1 {
      font-family: "Work Sans", sans-serif;
      text-transform: uppercase;
    }


    .article-on {
      animation: articleOn .9s 1;
      animation-fill-mode: forwards;
      position: relative;
      margin-top: 35px;

      @keyframes articleOn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
          margin-top: 0;
        }
      }

    }

    .article-off {
      animation: articleOff .9s 1;
      animation-fill-mode: forwards;
      position: relative;
      margin-top: 35px;

      @keyframes articleOff {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
          margin-top: 0;
        }
      }
    }


    div {
      margin-left: 53px;
      text-align: left;

      @media(max-width: $tablet) {
        margin-left: 20px;
      }

      h1 {
        font-size: 6vw;
        color: $primary-font-color;
        margin-bottom: 0;
        line-height: 80%;
        width: 70%;
        margin-bottom: 15px;
        animation: headingIn .5s 1;
        animation-fill-mode: forwards;
        position: relative;
        margin-top: 25px;
        font-family: "Work Sans", sans-serif;
        text-transform: uppercase;
        text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
        color: #fff;

        @media(max-width: $tablet) {
          font-size: 50px;
          width: 90%;
        }

        @keyframes headingIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
            margin-top: 0;
          }
        }

      }

      p {
        color: $primary-font-color;
        font-size: 18px;
        line-height: 140%;
      }

      h3 {
        margin-top: 0;
        color: $primary-font-color;
        width: 60%;
        line-height: 100%;
      }

      ul {
        list-style: none;
        padding-left: 0;

        p {
          margin-top: 0;
        }
      }

      a {
        color: #b3b3b3;
        text-decoration: none;
        font-weight: bold;
        margin: 0 18px 0 0;
        font-size: 14px;
        transition: .5s;

        &:hover {
          color: $primary-font-color;
          transition: .3s;
        }
      }

      img {
        border-radius: 100%;
        width: 90px;
      }

      .blog-date {
        color: $primary-font-color;
        font-weight: bold;
        font-size: 12px;
        margin-top: 15px;
        display: block;
      }
    }
  }

  .blog-header-image {
    height: 250px;
    margin-left: 0;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: relative;
    background-attachment: fixed;
    margin-bottom: 50px;

    @media(max-width: $tablet) {
      background-attachment: initial;
    }

    span {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
    }
  }

}