.reviews {
	padding-left: 130px;
    padding-top: 100px;
    position:relative;
}
.reviews p {
	font-size:18px;
	line-height:140%;
}
.reviews.animate-in .review-slide {
    padding: 50px;
    box-sizing: border-box;
    text-align: left;
    padding-left: 0;
    padding-right: 100px;
    position:relative;
    top:25px;
    opacity:0;
    transition:.6s;
    padding-bottom:0;
}
.reviews.animate-in.animate-show .review-slide {
	top:0;
	transition:.7s;
	opacity:1;
	transition-delay:1s;
}
.reviews .number-item-wrapper {
	position: absolute;
    left: 0;
    top: 0;
}
.reviews img.quote {
    position: absolute;
    right: 100px;
    top: 50px;
    transform: scale(3.5);
    opacity: .05;
}
.reviews.animate-in h4 {
    font-size: 2.3vw;
    font-weight: 700;
    width: 100%;
    box-sizing: border-box;
    top: 0;
    position: relative;
    transition: .5s;
    color: #222;
    font-family: "Work Sans", sans-serif;
    line-height: 110%;
    opacity: 1;
    padding-top: 25px;
    text-align: left;
    margin: 0;
    opacity:0;
    transition:.4s;
}
.reviews.animate-in .review-link {
    margin-bottom: 100px;
    opacity:0;
    transition:.4s;
    text-align:left;
    display: block;
    width: 143px;
    background: #caffbf;
    padding:15px;
}
.reviews.animate-in.animate-show .review-link {
	opacity:1;
	transition-delay:1s;
	transition:.4s;
}

.reviews.animate-in.animate-show h4 {
	opacity:1;
	transition:.6s;
	transition-delay:1s;
}
.reviews .slick-prev {
    left: -75px;
}

@media(max-width:800px) {
	.reviews {
		padding-left:25px;
	}
	.reviews.animate-in h4 {
		font-size:27px;
	}
	.reviews .number-item-wrapper {
		margin:50px 0px 0px 50px !important;
	}
}